.logo {
  width: 150px; /* Default size for desktop */
  margin-right: 10px; /* Space between logo and title */
}

.title {
  font-size: 4rem; /* Default size for desktop */
  font-weight: bold;
  text-shadow: 4px 4px #B9D9EB;
}


.social-links {
  margin-top: 4px; /* Adds space between the title and social links */
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-link {
  margin: 0 10px; /* Spacing between social icons */
  color: #333; /* Default icon color */
  transition: color 0.3s, transform 0.3s; /* Smooth color transition on hover */
}

.social-link:hover {
  color: #B9D9EB; /* Color change on hover */
  transform: scale(1.2); /* Grow effect on hover */
}

.custom-nav-link {
  border: 4px solid transparent; /* Default border style */
  transition: background-color 0.3s ease;
  border-radius: 5px; /* Optional: to match the rounded style */
}

.custom-nav-link:hover {
  background-color: #B9D9EB; /* Background color on hover */
}

.selected-link {
  background-color: #B9D9EB; /* Background color for selected link */
  text-decoration: underline; /* Add underline */
}

/* Container for both carousels */
.playlists-container {
  margin: 20px auto;
  max-width: 1000px;  /* Limit the maximum width of the carousels */
}

/* Each playlist section */
.playlist-section {
  margin-bottom: 40px; /* Space between each playlist section */
}

/* Carousel item - Ensure proper image resizing and centering */
.carousel-item {
  padding: 5px;  /* Space between the items */
  text-align: center; /* Center the image and caption */
}

.carousel-item img {
  border-radius: 10px;
  max-height: 200px; /* Resizing the image down */
  object-fit: cover;  /* Keep image proportional */
  width: auto;  /* Prevent the image from stretching */
  margin: 0 auto;  /* Center the image horizontally */
}

/* Media query for tablets (768px and below) */
@media (max-width: 768px) {
  .logo {
    width: 100px; /* Smaller size for tablets */
  }

  .title {
    font-size: 3rem; /* Smaller font size for tablets */
    text-shadow: 3px 3px #B9D9EB;
  }

  .carousel-item img {
    max-height: 250px; /* Slightly larger images on mobile */
  }
}

/* Media query for mobile phones (576px and below) */
@media (max-width: 576px) {
  .logo {
    width: 80px; /* Even smaller size for mobile phones */
  }

  .title {
    font-size: 2.5rem; /* Even smaller font size for mobile phones */
    text-shadow: 2px 2px #B9D9EB;
  }

  .social-link {
    margin: 0 10px; /* Spacing between social icons */
    color: #333; /* Default icon color */
    transition: color 0.3s, transform 0.3s; /* Smooth color transition on hover */
  }

  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #tagline {
    display: flex; 
    justify-content: space-between;
    flex-direction: row-reverse; /* This reverses the order */
  }

  #tagline.no-flex {
    flex-direction: column-reverse; /* This reverses the order */  
  }

  #tagline-div {
    display: block;
  }

  .navbar-toggler {
    border: #B9D9EB; /* Remove the default border */
    color: #B9D9EB; /* Change the color of the toggler */
  }

}


/* Remove default link styling */
.navbar-link {
  text-decoration: none;  /* Removes the underline */
  color: inherit;         /* Inherits the color from the parent */
}

.navbar-link:hover {
  text-decoration: none;  /* Ensures no underline appears on hover */
}

/* Optional: Use display contents to prevent <Link> from messing with layout */
.navbar-link {
  display: contents; /* Makes the <Link> element behave as if it doesn't exist layout-wise */
}
